var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('b-card',{staticClass:"product-form"},[_c('b-card-header',[_c('h4',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t('Create')))]),_c('b-card-text',{staticClass:"font-medium-5 mb-0"},[_c('b-link',{attrs:{"to":"/product-list"}},[_c('b-button',{staticClass:"mr-1",attrs:{"type":"reset","variant":"outline-secondary"}},[_vm._v(" Trờ về ")])],1),_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v("Lưu")])],1)],1),_c('b-card-body',[_c('validation-observer',{ref:"formRules"},[_c('b-row',[_c('b-col',{attrs:{"md":"12","lg":"4"}},[_c('b-row',[_c('b-col',{attrs:{"md":"6","lg":"12"}},[_c('b-form-group',[_c('custom-label',{attrs:{"forLabel":'name',"text":'Tên',"required":true}}),_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false : null,"placeholder":"Tên"},model:{value:(_vm.input.name),callback:function ($$v) {_vm.$set(_vm.input, "name", $$v)},expression:"input.name"}}),(failedRules.hasOwnProperty('required'))?_c('small',{staticClass:"text-danger"},[_vm._v(" Vui lòng nhập ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"md":"6","lg":"12"}},[_c('b-row',[_c('b-col',[_c('b-form-group',[_c('custom-label',{attrs:{"forLabel":"displayNumber","text":"Số thứ tự","required":true}}),_c('validation-provider',{attrs:{"name":"displayNumber","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('b-form-input',{attrs:{"type":"number","id":"displayNumber","state":errors.length > 0 ? false : null,"placeholder":"Số thứ tự"},model:{value:(_vm.input.displayNumber),callback:function ($$v) {_vm.$set(_vm.input, "displayNumber", $$v)},expression:"input.displayNumber"}}),(failedRules.hasOwnProperty('required'))?_c('small',{staticClass:"text-danger"},[_vm._v(" Vui lòng nhập ")]):_vm._e()]}}])})],1)],1),_c('b-col',[_c('b-form-group',[_c('custom-label',{attrs:{"forLabel":'status',"text":'Trạng thái',"required":true}}),_c('validation-provider',{attrs:{"name":"status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('b-form-select',{attrs:{"id":"status","state":errors.length > 0 ? false : null},model:{value:(_vm.input.status),callback:function ($$v) {_vm.$set(_vm.input, "status", $$v)},expression:"input.status"}},_vm._l((_vm.productStatusOptions),function(item){return _c('b-form-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(_vm.$t(item.text))+" ")])}),1),(failedRules.hasOwnProperty('required'))?_c('small',{staticClass:"text-danger"},[_vm._v(" Vui lòng chọn ")]):_vm._e()]}}])})],1)],1)],1)],1),_c('b-col',{attrs:{"md":"6","lg":"12"}},[_c('b-form-group',[_c('custom-label',{attrs:{"forLabel":'productCategory',"text":'Danh mục sản phẩm',"required":true}}),_c('validation-provider',{attrs:{"name":"productCategory","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('b-form-select',{attrs:{"id":"productCategory","options":_vm.productCategoryOptions,"state":errors.length > 0 ? false : null},model:{value:(_vm.input.productCategoryId),callback:function ($$v) {_vm.$set(_vm.input, "productCategoryId", $$v)},expression:"input.productCategoryId"}}),(failedRules.hasOwnProperty('required'))?_c('small',{staticClass:"text-danger"},[_vm._v(" Vui lòng chọn ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"md":"6","lg":"12"}},[_c('b-form-group',[_c('custom-label',{attrs:{"forLabel":'type',"text":'Loại sản phẩm',"required":true}}),_c('validation-provider',{attrs:{"name":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('b-form-checkbox-group',{staticClass:"d-flex justify-content-between",attrs:{"id":"checkbox-group-1","name":"type","state":errors.length > 0 ? false : null},model:{value:(_vm.input.types),callback:function ($$v) {_vm.$set(_vm.input, "types", $$v)},expression:"input.types"}},_vm._l((_vm.productTypeOptions),function(item){return _c('b-form-checkbox',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(_vm.$t(item.text))+" ")])}),1),(failedRules.hasOwnProperty('required'))?_c('small',{staticClass:"text-danger"},[_vm._v(" Vui lòng chọn ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"md":"6","lg":"12"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('custom-label',{attrs:{"forLabel":'PaperType',"text":'Loại giấy',"required":true}}),_c('b-form-radio-group',{staticClass:"d-flex justify-content-between",attrs:{"options":_vm.paperTypeOptions,"aria-describedby":ariaDescribedby,"name":"paperType-radio-inline"},model:{value:(_vm.input.paperType),callback:function ($$v) {_vm.$set(_vm.input, "paperType", $$v)},expression:"input.paperType"}})]}}])})],1),_c('b-col',{attrs:{"md":"6","lg":"12"}},[_c('b-form-group',[_c('custom-label',{attrs:{"forLabel":'pricingType',"text":'Cách tính giá',"required":true}}),_c('validation-provider',{attrs:{"name":"status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('b-form-select',{attrs:{"id":"pricingType","state":errors.length > 0 ? false : null},model:{value:(_vm.input.productPricingType),callback:function ($$v) {_vm.$set(_vm.input, "productPricingType", $$v)},expression:"input.productPricingType"}},_vm._l((_vm.productPricingTypeOptions),function(item){return _c('b-form-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(_vm.$t(item.text))+" ")])}),1),(failedRules.hasOwnProperty('required'))?_c('small',{staticClass:"text-danger"},[_vm._v(" Vui lòng chọn ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('b-form-checkbox',{attrs:{"state":!_vm.input.haveMachining && !_vm.input.noMachining
                        ? false
                        : null},model:{value:(_vm.input.haveMachining),callback:function ($$v) {_vm.$set(_vm.input, "haveMachining", $$v)},expression:"input.haveMachining"}},[_vm._v(" Có cán màng ")])],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('b-form-checkbox',{attrs:{"state":!_vm.input.haveMachining && !_vm.input.noMachining
                        ? false
                        : null},model:{value:(_vm.input.noMachining),callback:function ($$v) {_vm.$set(_vm.input, "noMachining", $$v)},expression:"input.noMachining"}},[_vm._v(" Không cán màng ")])],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('b-form-checkbox',{model:{value:(_vm.input.isAnotherProduct),callback:function ($$v) {_vm.$set(_vm.input, "isAnotherProduct", $$v)},expression:"input.isAnotherProduct"}},[_vm._v(" Sản phẩm khác ")])],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('b-form-checkbox',{model:{value:(_vm.input.isHiddenPrice),callback:function ($$v) {_vm.$set(_vm.input, "isHiddenPrice", $$v)},expression:"input.isHiddenPrice"}},[_vm._v(" Không báo giá ")])],1)],1),_c('b-col',{attrs:{"md":"6","lg":"12"}},[_c('b-form-group',[_c('custom-label',{attrs:{"forLabel":'detail',"text":'Đặc điểm cơ bản'}}),_c('b-form-textarea',{attrs:{"id":"detail","placeholder":"Đặc điểm cơ bản","rows":"4"},model:{value:(_vm.input.basicCharacteristics),callback:function ($$v) {_vm.$set(_vm.input, "basicCharacteristics", $$v)},expression:"input.basicCharacteristics"}})],1)],1),_c('b-col',{attrs:{"md":"6","lg":"12"}},[_c('b-form-group',[_c('custom-label',{attrs:{"forLabel":'selUrl',"text":'Seo Url',"required":true}}),_c('validation-provider',{attrs:{"name":"seoUrl","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
                        var failedRules = ref.failedRules;
return [_c('b-form-input',{attrs:{"id":"seoUrl","state":errors.length > 0 ? false : null,"placeholder":"Seo Url"},model:{value:(_vm.input.seoUrl),callback:function ($$v) {_vm.$set(_vm.input, "seoUrl", $$v)},expression:"input.seoUrl"}}),(failedRules.hasOwnProperty('required'))?_c('small',{staticClass:"text-danger"},[_vm._v(" Vui lòng nhập ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"md":"6","lg":"12"}},[_c('b-form-group',[_c('custom-label',{attrs:{"forLabel":'metaTitle',"text":'Tiêu đề Seo Meta'}}),_c('b-form-input',{attrs:{"id":"metaTitle","placeholder":"Tiêu đề Seo Meta"},model:{value:(_vm.input.metaTitle),callback:function ($$v) {_vm.$set(_vm.input, "metaTitle", $$v)},expression:"input.metaTitle"}})],1)],1),_c('b-col',{attrs:{"md":"6","lg":"12"}},[_c('b-form-group',[_c('custom-label',{attrs:{"forLabel":'metaDescription',"text":'Mô tả Seo Meta'}}),_c('b-form-textarea',{attrs:{"id":"metaDescription","placeholder":"Mô tả Seo Meta","rows":"4"},model:{value:(_vm.input.metaDescription),callback:function ($$v) {_vm.$set(_vm.input, "metaDescription", $$v)},expression:"input.metaDescription"}})],1)],1),_c('b-col',{attrs:{"md":"6","lg":"12"}},[_c('b-form-group',{staticClass:"images-control"},[_c('custom-label',{attrs:{"forLabel":'image',"text":'Hình ảnh',"required":true}}),_c('validation-provider',{attrs:{"name":"images","rules":_vm.product && _vm.input.imageLinks.length > 0 ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
                        var failedRules = ref.failedRules;
return [_c('b-form-file',{attrs:{"state":errors.length > 0 ? false : null,"name":"images","accept":"image/*","value":_vm.input.imageFiles},on:{"change":_vm.imageChange}}),(failedRules.hasOwnProperty('required'))?_c('small',{staticClass:"text-danger"},[_vm._v(" Vui lòng nhập ")]):_vm._e()]}}])})],1)],1)],1)],1),_c('b-col',{attrs:{"md":"12","lg":"8"}},[_c('b-row',[_c('b-form-group',{staticClass:"pf-text-editor w-100"},[_c('custom-label',{attrs:{"forLabel":'description',"text":'Mô tả'}}),_c('quill-editor',{attrs:{"id":"Description"},model:{value:(_vm.input.description),callback:function ($$v) {_vm.$set(_vm.input, "description", $$v)},expression:"input.description"}})],1)],1),_c('b-row',[(
                  _vm.input.imageFiles.length > 0 || _vm.input.imageLinks.length > 0
                )?_c('div',{staticClass:"d-inline-flex flex-wrap"},[_vm._l((_vm.input.imageLinks),function(img){return _c('div',{key:img,staticClass:"img-item w-25"},[_c('b-img',{staticClass:"mt-1 w-100",attrs:{"thumbnail":"","src":(_vm.VUE_APP_API_BASE_HOST + "/" + img)}}),_c('feather-icon',{attrs:{"icon":"XCircleIcon","size":"21"},on:{"click":function($event){return _vm.deleteImgLink(img)}}})],1)}),_vm._l((_vm.input.imageFiles),function(img){return _c('div',{key:img.name,staticClass:"img-item w-25"},[_c('b-img',{staticClass:"mt-1 w-100",attrs:{"thumbnail":"","src":_vm.URL.createObjectURL(img)}}),_c('feather-icon',{attrs:{"icon":"XCircleIcon","size":"21"},on:{"click":function($event){return _vm.deleteImgFile(img)}}})],1)})],2):_vm._e()])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }