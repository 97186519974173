import { PRODUCT_LIST_ROUTE_NAME, PRODUCT_ROUTE_NAME } from '@/router';
import { BreadcrumbItem } from '@/store/breadcrumb/breadcrumb.module-types';

export class BreadcrumbBuilder {
  private breadcrumbItems: BreadcrumbItem[];
  constructor() {
    this.breadcrumbItems = [];
  }
  AddProductListBreadcrumb() {
    this.breadcrumbItems.push({
      to: { name: PRODUCT_LIST_ROUTE_NAME },
      text: 'Product',
      needGlobalization: true
    });
    return this;
  }

  AddProductBreadcrumb(text: string) {
    this.breadcrumbItems.push({
      text,
      active: true
    });
    return this;
  }

  Build(): BreadcrumbItem[] {
    return this.breadcrumbItems;
  }
}
