import { Vue, Component, Watch } from 'vue-property-decorator';
import BCardCode from '@core/components/b-card-code/BCardCode.vue';
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BForm,
  BCardText,
  BImg,
  BBadge,
  BFormTextarea,
  BFormFile,
  BLink,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormSelectOption,
  BFormRadioGroup
} from 'bootstrap-vue';
import {
  ValidationProvider,
  ValidationObserver
} from 'vee-validate/dist/vee-validate.full';
import {
  ProductNamespace,
  ProductGetter,
  ProductAction
} from '@/store/product/product.module-types';
import {
  ProductViewModel,
  ProductStatus,
  FileParameter,
  ProductCategoryListViewModel,
  ProductType,
  PaperType,
  ProductPricingType
} from '@/api/api';
import { enumToDropdownOptions } from '@/utility/utils';
import { DropdownOption } from '@/utility/dropdowns/dropdownOptions';
import { dispatchProductAction } from '@/store/product/product.dispatch';
import { productListDispatcher } from '../productList/store/product-list.module';
import ApiClientFactory from '@/api/apiClientFactory';
import { quillEditor } from 'vue-quill-editor';
import router from '@/router';
import CustomLabel from '@/@core/components/labels/CustomLabel.vue';
import {
  productCategoryListDispatcher,
  ProductCategoryListInput,
  productCategoryListNamespace
} from '@/views/productCategory/productCategoryList/store/productCategory-list.module';
import {
  ListGetter,
  ListGetterTypes
} from '@/utility/pagination/get-list.module-type';
import { dispatchBreadcrumbAction } from '@/store/breadcrumb/breadcrumb.dispatch';
import { BreadcrumbAction } from '@/store/breadcrumb/breadcrumb.module-types';
import { BreadcrumbBuilder } from '@/utility/breadcrumb/breadcrumbFactory';

@Component({
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardCode,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BForm,
    BCardText,
    BImg,
    BBadge,
    BFormTextarea,
    BFormFile,
    BLink,
    quillEditor,
    ValidationProvider,
    ValidationObserver,
    CustomLabel,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormSelectOption,
    BFormRadioGroup
  }
})
export default class ProductForm extends Vue {
  @ProductNamespace.Getter(ProductGetter.product)
  product!: ProductViewModel;
  @productCategoryListNamespace.Getter(ListGetter.state)
  productCategoryListState!: ListGetterTypes<
    ProductCategoryListViewModel,
    ProductCategoryListInput
  >[ListGetter.state];

  input = this.getDefaultInputValue();
  productId = '';
  VUE_APP_API_BASE_HOST = process.env.VUE_APP_API_BASE_HOST;
  URL = URL;
  productStatusOptions: DropdownOption[] = enumToDropdownOptions(ProductStatus);
  productCategoryOptions: DropdownOption[] = [];
  productTypeOptions: DropdownOption[] = enumToDropdownOptions(ProductType);
  paperTypeOptions: DropdownOption[] = enumToDropdownOptions(PaperType).map(
    (s) => {
      return {
        ...s,
        text: this.$t(s.text)
      } as DropdownOption;
    }
  );
  productPricingTypeOptions: DropdownOption[] = enumToDropdownOptions(
    ProductPricingType
  ).map((s) => {
    return {
      ...s,
      text: this.$t(s.text)
    } as DropdownOption;
  });

  $refs!: {
    formRules: InstanceType<typeof ValidationProvider>;
  };

  mounted() {
    this.productId = this.$route.params.id;
    this.getProductCategory();
  }

  getProductCategory() {
    productCategoryListDispatcher.updateInput({
      itemsPerPage: 1000
    });
    productCategoryListDispatcher.load();
  }

  @Watch('productCategoryListState', { deep: true })
  productCategoryListUpdated(): void {
    this.productCategoryOptions = this.productCategoryListState.items.map(
      (x) =>
        ({
          value: x.id,
          text: x.name
        } as DropdownOption)
    );
    this.input.productCategoryId =
      this.productCategoryOptions.length > 0
        ? this.productCategoryOptions[0].value
        : 0;
  }

  @Watch('productId')
  getProductById(): void {
    if (this.productId) {
      dispatchProductAction(
        ProductAction.loadProduct,
        parseInt(this.productId)
      );
    } else {
      dispatchProductAction(ProductAction.clearProduct);
    }
  }

  @Watch('product')
  setBreadcrumb() {
    dispatchBreadcrumbAction(
      BreadcrumbAction.setItems,
      new BreadcrumbBuilder()
        .AddProductListBreadcrumb()
        .AddProductBreadcrumb(
          this.product
            ? (this.product.name as string)
            : this.$t('Create').toString()
        )
        .Build()
    );
  }

  @Watch('product')
  productUpdated(): void {
    if (this.product) {
      this.input = {
        name: this.product.name as string,
        price: this.product.price,
        imageLinks: this.product.imageLinks as string[],
        imageFiles: [] as any[],
        status: this.product.status as ProductStatus,
        seoUrl: this.product.seoUrl as string,
        metaTitle: this.product.metaTitle as string,
        metaDescription: this.product.metaDescription as string,
        description: this.product.description as string,
        basicCharacteristics: this.product.basicCharacteristics as string,
        productCategoryId: this.product.productCategoryId,
        types: this.product.types as number[],
        paperType: this.product.paperType,
        haveMachining: this.product.haveMachining,
        noMachining: this.product.noMachining,
        isAnotherProduct: this.product.isAnotherProduct,
        isHiddenPrice: this.product.isHiddenPrice,
        displayNumber: this.product.displayNumber ?? 0,
        productPricingType: this.product.productPricingType
      };
    } else {
      this.input = this.getDefaultInputValue();
    }
  }

  getDefaultInputValue() {
    return {
      name: '',
      price: 0,
      imageLinks: [] as string[],
      imageFiles: [] as any[],
      status: ProductStatus.Active,
      seoUrl: '',
      metaTitle: '',
      metaDescription: '',
      description: '',
      basicCharacteristics: '',
      productCategoryId: 0,
      types: [ProductType.Normal] as number[],
      paperType: PaperType.All as number,
      haveMachining: true,
      noMachining: true,
      isAnotherProduct: false,
      isHiddenPrice: false,
      displayNumber: 0,
      productPricingType: ProductPricingType.Size
    };
  }

  add(): void {
    const client = new ApiClientFactory().productClient();

    const images: FileParameter[] = this.input.imageFiles.map((img) => {
      return {
        data: img,
        fileName: (img as any).name
      };
    });

    client
      .create(
        this.input.name,
        this.input.description,
        this.input.basicCharacteristics,
        this.input.price,
        images,
        this.input.status,
        this.input.seoUrl,
        this.input.metaDescription,
        this.input.metaTitle,
        this.input.productCategoryId,
        this.input.types,
        this.input.paperType,
        this.input.haveMachining,
        this.input.noMachining,
        this.input.isAnotherProduct,
        this.input.isHiddenPrice,
        this.input.displayNumber,
        this.input.productPricingType
      )
      .then(() => {
        // show toast
        this.$bvToast.toast('Thêm mới thành công', {
          title: this.$t('Product').toString(),
          toaster: 'b-toaster-bottom-right',
          variant: 'success'
        });

        router.push({ path: '/product-list' });
      });
  }

  edit() {
    const client = new ApiClientFactory().productClient();
    const imageFiles: FileParameter[] = this.input.imageFiles.map((img) => {
      return {
        data: img,
        fileName: (img as any).name
      };
    });

    client
      .edit(
        this.product.id,
        this.input.name,
        this.input.description,
        this.input.basicCharacteristics,
        this.input.price,
        imageFiles,
        this.input.imageLinks,
        this.input.status,
        this.input.seoUrl,
        this.input.metaDescription,
        this.input.metaTitle,
        this.input.productCategoryId,
        this.input.types,
        this.input.paperType,
        this.input.haveMachining,
        this.input.noMachining,
        this.input.isAnotherProduct,
        this.input.isHiddenPrice,
        this.input.displayNumber,
        this.input.productPricingType
      )
      .then(() => {
        productListDispatcher.load();
        // show toast
        this.$bvToast.toast('Chỉnh sửa thành công', {
          title: this.$t('Product').toString(),
          toaster: 'b-toaster-bottom-right',
          variant: 'success'
        });
      });
  }

  deleteImgLink(img: string) {
    const index = this.input.imageLinks.findIndex((x) => x === img);
    this.input.imageLinks.splice(index, 1);
  }

  deleteImgFile(img: any) {
    const index = this.input.imageFiles.findIndex(
      (x: any) => x.name === img.name
    );
    this.input.imageFiles.splice(index, 1);
  }

  imageChange(event) {
    if (event.target.files && event.target.files.length > 0) {
      this.input.imageFiles.push(event.target.files[0]);
      console.log(this.input.imageFiles);
    }
  }

  submit(): void {
    this.$refs.formRules.validate().then((success) => {
      if (success) {
        if (this.product) {
          this.edit();
        } else {
          this.add();
        }
      }
    });
  }
}
